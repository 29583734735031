import fetch from "~/utils/fetch";

export default {
  visitsCount: async (tenantId = '') => {
    return fetch("POST", "/api/visits", {
      tenantId: tenantId
    },
    {
      Authorization: window.localStorage.getItem("token"),
    })
    .then(() => console.info("Visit save correctly"))
    .catch(() => {
      console.warn("Saving visit failed");
    });
  }
};
