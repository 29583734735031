import { Flex, Text } from "@chakra-ui/core";
import { DrawerTitle } from "@pency/components/structure/Drawer";
import React from "react";
import { useTranslation } from "~/i18n/hooks";
import OrderItem from "./OrderItem";
import OrderState from "./OrderState";

interface Props {
  order: any;
}

const CheckoutBody: React.FC<Props> = ({ order }) => {
  const t = useTranslation();
  return (
    <>
      <DrawerTitle>{t("cart.checkout.orderTitle")}</DrawerTitle>
      <Flex
        borderTop="1px solid #E4E6E8"
        borderBottom="1px solid #E4E6E8"
        padding="1.25rem 0"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="1rem"
        marginBottom="1rem"
        height="100%"
      >
        <Text fontWeight={600}>{t("cart.checkout.orderState")}</Text>
        <OrderState orderState={order.state} />
      </Flex>
      {order?.items?.map((item) => (
        <OrderItem key={item.id} item={item} />
      ))}
    </>
  );
};

export default CheckoutBody;
