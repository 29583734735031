import { Box } from "@chakra-ui/core";
import React from "react";
import { useTranslation } from "~/i18n/hooks";

interface Props {
  orderState: string;
}

const formatOrderState = (orderState: string) => {
  switch (orderState) {
    case "CHARGED":
      return "orders.orderStates.labels.charged";
    default:
      return "orders.orderStates.labels.pending";
  }
};

const OrderState: React.FC<Props> = ({ orderState }) => {
  const t = useTranslation();

  return (
    <Box
      backgroundColor="#99EAAA"
      borderRadius="0.25rem 0 0.25rem 0;"
      padding="0.25rem 2rem"
      fontSize="0.8rem"
      fontWeight={600}
    >
      {t(formatOrderState(orderState))}
    </Box>
  );
};

export default OrderState;
