export const getFee = (tier: string): number => {
  switch (tier) {
    case "premium":
    case "PLAN_TRIAL":
    case "PLAN_PREMIUM":
      return 1;
    case "commercial":
    case "PLAN_COMMERCIAL":
      return 1.5;
    default:
      return 2;
  }
};

export const getPencyFee = (tier: string, total: number): number => {
  const fee = getFee(tier);

  return (total * fee) / 100;
};
