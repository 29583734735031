import React from "react";
import FormControl from "@pency/components/form/FormControl";
import RadioGroup, {RadioRow} from "@pency/components/inputs/Radio";

import {RadioField} from "~/tenant/types";
import { useTranslation } from "~/i18n/hooks";

interface Props {
  value: string;
  field: RadioField;
  onChange: (value: string) => void;
}

const RadioFieldInput: React.FC<Props> = ({value, field, onChange}) => {
  const t = useTranslation()

  return (
    <FormControl isRequired={field.required} label={t(field.title)} width="100%">
      <RadioGroup value={value} onChange={(event) => onChange(event.target.value)}>
        {field.options.map((option) => (
          <RadioRow key={option.id} note={option.note} value={option.title}>
            {t(option.title)}
          </RadioRow>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioFieldInput;
