import React from "react";
import FormControl from "@pency/components/form/FormControl";
import { Text, Textarea } from "@chakra-ui/core";

import { LongText } from "~/tenant/types";
import { useTranslation } from "~/i18n/hooks";

interface Props {
  value: string;
  field: LongText;
  onChange: (value: string) => void;
}

const TextFieldInput: React.FC<Props> = ({ value, field, onChange }) => {
  const t = useTranslation();
  const getValueLength = () => {
    return value === undefined ? 0 : value.length;
  };


  return (
    <FormControl
      help={field?.note}
      isRequired={field?.required}
      label={t(field?.title)}
      width="100%"
      position='relative'
    >
      <Textarea
        maxLength={255}
        resize="none"
        variant="flushed"
        focusBorderColor="primary.300"
        value={value || ""}
        onChange={(event) => onChange(event.target.value || "")}
      >
      </Textarea>
      <Text color='primary.500' position='absolute' right='1px' bottom='-1.6rem'>
          {`${getValueLength()}/255`}
      </Text>
    </FormControl>
  );
};

export default TextFieldInput;
