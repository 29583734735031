import React from "react";
import {Stack, Text, Flex} from "@chakra-ui/core";
import Button from "@pency/components/controls/Button";
import FieldsForm from "../../forms/FieldsForm";

import {DrawerTitle, DrawerBody, DrawerFooter} from "@pency/components/structure/Drawer";
import {Field} from "~/tenant/types";
import {usePrice, useTranslation} from "~/i18n/hooks";
import ArrowLeftIcon from "@pency/components/icons/ArrowLeft";
import CrossIcon from "@pency/components/icons/Cross";
import { addFinancialCost, getCost, getFinancialCost, getFinancialText, getTotal, hasDelivery, hasFinancialCost } from "~/cart/selectors";
import { CartItem } from "~/cart/types";

interface Props {
  fields: Field[];
  items: CartItem[];
  onSubmit: (fields: Field[], formValues, total, getTotalWithDelivery) => void;
  onClose: VoidFunction;
  onPrevious: VoidFunction;
}

const Fields: React.FC<Props> = ({fields, items, onSubmit, onClose, onPrevious}) => {
  const [isLoading, toggleLoading] = React.useState(false);
  const t = useTranslation();
  const p = usePrice();

  function handleSubmit(event: React.MouseEvent, submit: () => Promise<void>) {
    event.stopPropagation();

    toggleLoading(true);

    submit().finally(() => toggleLoading(false));
  }

  const total = getTotal(items)

  const getTotalWithDelivery = (formValues): number => {
    const delivery = hasDelivery(formValues, t, fields)
    const deliveryCost = getCost(fields)
    const {financialCost} = addFinancialCost(formValues, total)
    let cost = total

    if (financialCost) {
      cost = financialCost
    }

    if (delivery) {
      cost = cost + deliveryCost
    }
    
    return cost
  }

  return (
    <FieldsForm defaultValues={fields} onSubmit={onSubmit} total={total} getTotalWithDelivery={getTotalWithDelivery}>
      {({form, submit, formValues}) => (
        <>
          <DrawerBody overflowY="auto">
            <ArrowLeftIcon
              background="white"
              boxShadow="md"
              cursor="pointer"
              left={0}
              marginTop={4}
              paddingX={4}
              paddingY={3}
              position="absolute"
              roundedRight="lg"
              top={0}
              onClick={onPrevious}
            />
            <CrossIcon
              background="white"
              boxShadow="md"
              cursor="pointer"
              marginTop={4}
              paddingX={4}
              paddingY={3}
              position="absolute"
              right={0}
              roundedLeft="lg"
              top={0}
              onClick={onClose}
            />
            <Stack marginTop={20} spacing={6}>
              <DrawerTitle>{t("cart.completeOrder")}</DrawerTitle>
              {form}              
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopColor="gray.100" borderTopWidth={1} marginTop={2}>
            <Stack spacing={4} width="100%">
            {hasFinancialCost(formValues) && (
                <Flex alignItems="center" fontSize="lg" fontWeight={500} justifyContent="space-between">
                <Text>{getFinancialText(formValues)}</Text>
                <Text>{p(getFinancialCost(formValues, total))}</Text>
              </Flex>
              )}
              {hasDelivery(formValues, t, fields) && (
                <Flex alignItems="center" fontSize="lg" fontWeight={500} justifyContent="space-between">
                <Text>{'Envio'}</Text>
                <Text>{p(getCost(fields))}</Text>
              </Flex>
              )}
              <Flex alignItems="center" fontSize="lg" fontWeight={500} justifyContent="space-between">
                <Text>{t("cart.estimatedTotal")}</Text>
                <Text>{p(getTotalWithDelivery(formValues))}</Text>
              </Flex>
                <Button 
                  boxShadow="lg" 
                  size="lg" 
                  isLoading={isLoading}
                  variantColor="primary" 
                  onClick={(event) => handleSubmit(event, submit)}>
                    {t("cart.placeOrder")}
                </Button>
            </Stack>
          </DrawerFooter>
        </>
      )}
    </FieldsForm>
  );
};

export default Fields;
