import React from "react";
import RadioGroup, {RadioRow} from "@pency/components/inputs/Radio";

import {Option, Variant} from "~/product/types";
import {usePrice} from "~/i18n/hooks";

interface Props {
  value: Variant;
  onChange: (value: Variant) => void;
}

const SingleInput: React.FC<Props> = ({value, onChange}) => {
  const p = usePrice();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const option: Option | undefined = value.options.find((option) => option.id === event.target.value);
    
    if (option) {
      onChange({...value, value: [option]});
    }
  }

  return (
    <RadioGroup value={value.value?.[0]?.id} onChange={handleChange}>
      {value.options
        ?.filter((option) => option.enabled)
        .map((option) => (
          <RadioRow
            key={option.id}
            note={option.price ? `+ ${p(option.price)}` : undefined}
            value={option.id}
          >
            {option.title}
          </RadioRow>
        ))}
    </RadioGroup>
  );
};

export default SingleInput;
