import {i18n, TFunction} from "next-i18next";

import {CartItem, CostValues} from "~/cart/types";
import {Field} from "~/tenant/types";
import {getVariantsString} from "~/product/selectors";
import {formatPrice} from "~/i18n/selectors";
import {displayProp, getFormattedPrice} from "~/cart/selectors";

export const ORDER_STATES = {
  FREE_TIER: { label: "orders.orderStates.labels.historical", color: "ordersStates.300", description: "admin.orders.orderStates.description.historical"},
  PENDING: { label: "orders.orderStates.labels.pending", color: "ordersStates.100", description: "admin.orders.orderStates.description.pending" },
  CONFIRMED: { label: "orders.orderStates.labels.confirmed", color: "ordersStates.100", description: "admin.orders.orderStates.description.confirmed" },
  CHARGED: { label: "orders.orderStates.labels.charged", color: "ordersStates.200", description: "admin.orders.orderStates.description.charged" },
  SENT: { label: "orders.orderStates.labels.sent", color: "ordersStates.300", description: "admin.orders.orderStates.description.sent" },
  DELIVERED: { label: "orders.orderStates.labels.delivered", color: "ordersStates.400", description: "admin.orders.orderStates.description.delivered" },
  CANCELLED: { label: "orders.orderStates.labels.cancelled", color: "ordersStates.500", description: "admin.orders.orderStates.description.cancelled" },
};

function _getFields(fields: Field[], t) {
  if (!fields) return "";

  const newFields = fields.map((field) => {
    if (field.id === "default_payment_mode" && field.active) {
      const fieldValue = field?.value?.split("_")[3] ?? t(field.value)
      return {
        ...field,
        value: fieldValue
      }
    }
    return field
  })

  if(newFields[0]?.title) {
    return newFields
    .filter(({title, value}) => title && value)
    .map(({title, value}) => `${t(title)}: *${value}*`)
    .join("\n");
  }else {
    let obj;
    Object.entries(fields).map((key) => {
      obj = {
        ...obj,
        ...key[1],
      };
    });

    return Object.entries(obj)
      .map((key) => `${t(key[0])}: *${key[1]}*`)
      .join("\n");
  }
}

export function getOrderFields(fields: Field[], t) {
  if (!fields) return "";

  const newFields = fields.map((field) => {
    if (field.id === "default_payment_mode" && field.active) {
      const fieldValue = field?.value?.split("_")[3] ?? t(field.value)
      return {
        ...field,
        value: fieldValue
      }
    }
    return field
  });
  const fieldsArray = newFields.filter(({title, value}) => title && value).map(({title, value}) => {
      return { [t(title)]: value }
  });
  return fieldsArray
}

function _getPreferenceFooter(preference?: string) {
  if (!preference) return "";

  return `----------

${i18n?.t("message.paymentNote")}
${preference}`;
}

function _getItems(items: CartItem[]): string {
  return items
    .map(
      (item) =>
        `— ${[
          item.count > 1 ? `*[ ${item.count} ]*` : "",
          item.product.title,
          item.variants ? `_${getVariantsString(item.variants)}_` : "",
          item.note ? `(${item.note})` : "",
          `> *${getFormattedPrice(item)}*`,
        ]
          .filter(Boolean)
          .join(" ")}`,
    )
    .join("\n");
}

export function getMessage(
  items: CartItem[],
  orderId: string,
  t: TFunction,
  orderPrices: CostValues,
  fields?: Field[],
  state?: string,
  preference?: string,
): string {
  return (
    `${i18n?.t("message.order")}: *${orderId}*` +
    (state ? "\n" + `${t('cart.checkout.orderState')}${t(state)}` :  "") +
    "\n\n" +
    _getItems(items) +
    "\n\n" +
    //`*${i18n.t("message.rawTotal")}: ${formatPrice(getTotal(items))}*\n` +
    `${
      displayProp(orderPrices.financial)
        ? "*" + i18n?.t(orderPrices.opt) + ":" + formatPrice(orderPrices.financial) + "*\n"
        : ""
    }` +
    `${
      displayProp(orderPrices.delivery)
        ? "*" + i18n?.t("message.delivery") + ":" + formatPrice(orderPrices.delivery) + "*\n"
        : ""
    }` +
    `*${i18n?.t("message.total")}: ${formatPrice(orderPrices.cost)}*` +
    (fields ? "\n\n" + _getFields(fields, t) : "") +
    (preference ? `\n\n${_getPreferenceFooter(preference)}` : "")
  );
}
