import React from "react";
import {Grid, GridProps, Stack, Box, Text} from "@chakra-ui/core";
import ChevronUpIcon from "@pency/components/icons/ChevronUp";
import ChevronDownIcon from "@pency/components/icons/ChevronDown";

import {Product} from "../../../types";
import ProductCard from "../../ProductCard";
import { formatImageUrl } from "~/product/utils/formatImage";

interface Props extends GridProps {
  isSelected: boolean;
  products: Product[];
  category: Product["category"];
  onProductClick: (product: Product) => void;
  onCategoryClick?: (category: Product["category"]) => void;
}

const Portrait: React.FC<Props> = ({
  products,
  category,
  onCategoryClick,
  onProductClick,
  isSelected,
  ...props
}) => {
  const element = React.useRef<HTMLElement>();
  const image = React.useMemo(() => {
    const product = products.find((product) => product.images?.find(Boolean));

    return product?.images.find(Boolean);
  }, [products]);

  React.useEffect(() => {
    if (isSelected) {
      element.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }
  }, [isSelected]);

  return (
    <Stack ref={element} spacing={0}>
      <Stack
        isInline
        alignItems="center"
        backgroundImage={`url(${formatImageUrl(image ?? '', 'og')})`}
        backgroundPosition={{base: "center", sm: "right center"}}
        backgroundRepeat="no-repeat"
        backgroundSize={{base: "cover", sm: "50%"}}
        cursor="pointer"
        fontWeight={500}
        justifyContent="flex-start"
        paddingX={4}
        paddingY={6}
        position="relative"
        spacing={3}
        onClick={() => onCategoryClick && onCategoryClick(category)}
      >
        <Box
          background={{
            base: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%);",
            sm: "linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.5) 100%);",
          }}
          height="100%"
          left={0}
          position="absolute"
          top={0}
          width="100%"
          zIndex={0}
        />
        <Stack isInline alignItems="center" justifyContent="space-between" spacing={3} width="100%">
          <Stack isInline alignItems="center" spacing={3}>
            <Text
              alignItems="center"
              backgroundColor="gray.100"
              borderRadius="md"
              display="flex"
              height={6}
              justifyContent="center"
              lineHeight="normal"
              minWidth={6}
              padding={1}
              textAlign="center"
              zIndex={1}
            >
              {products.length}
            </Text>
            <Text fontSize="lg" zIndex={1}>
              {category}
            </Text>
          </Stack>
          <Box color="black" zIndex={1}>
            {isSelected ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Box>
        </Stack>
      </Stack>
      {isSelected ? (
        <Grid
          autoRows="auto"
          gridGap={{base: 0, sm: 8}}
          paddingY={{base: 4, sm: 8}}
          templateColumns={{
            base: "repeat(auto-fill, minmax(320px,1fr))",
            sm: "repeat(auto-fill, minmax(400px,1fr))",
          }}
          {...props}
        >
          {products.map((product) => (
            <ProductCard
              key={product.id}
              layout="landscape"
              product={product}
              onClick={() => onProductClick(product)}
            />
          ))}
        </Grid>
      ) : null}
    </Stack>
  );
};

export default Portrait;
