import React from 'react'
import { CheckField as CheckFieldInterface } from '~/tenant/types';
import FormControl from "@pency/components/form/FormControl";
import RadioGroup, {RadioRow} from "@pency/components/inputs/Radio";
import { useTranslation } from '~/i18n/hooks';
import { formatPrice } from '~/i18n/selectors';

interface Props {
    value: string;
    field: CheckFieldInterface;
    onChange: (value: string) => void;
  }

const CheckField:React.FC<Props> = ({value, field, onChange}) => {
  const t = useTranslation()
  const getCost = (option) => {
    return ["Custo fixo", "Costo fijo", 'Fixed cost'].includes(option.selected) 
      ? formatPrice(field.cost) 
      : '' 
  }
  
  return (
    <FormControl help={field.note} isRequired={field.required} label={t(field.title)} width="100%">
    <RadioGroup value={value} onChange={(event) => onChange(event.target.value)}>
      {field.options.map((option) => {
        const title = t(option.title)
        return (
          <RadioRow key={option.id} note={getCost(option)} value={title}>
          {title}
        </RadioRow>
        )
      })}
    </RadioGroup>
  </FormControl>
  )
}

export default CheckField