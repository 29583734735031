import React from "react";
import CustomDrawer from "./CustomDrawer";
import { IDrawer } from "@chakra-ui/core";
import { useRouter } from "next/router";
import { useTenant } from "~/tenant/hooks";
import Checkout from "../CartSummaryDrawer/Checkout";
import { CartItem } from "~/cart/types";
import { Field } from "~/tenant/types";

interface Props extends Omit<IDrawer, "children"> {
  onClose: any;
  order: any;
  fields: Field[];
  items: CartItem[];
  pageId?: string
}

const CardOrderDrawer: React.FC<Props> = ({
  items,
  fields,
  isOpen,
  order,
  pageId = 'store_page'
}) => {
  const router = useRouter();
  const tenant = useTenant();

  const closeDrawer = () => {
    //onClose(e);
    router.push(`/${tenant.slug}`);
  };
  return (
    <CustomDrawer isOpen={isOpen!} isDisabled={true} id="cart-item">
      <Checkout order={order} items={items} fields={fields} onClose={order && closeDrawer} pageId={pageId} />
    </CustomDrawer>
  );
};

export default CardOrderDrawer;
