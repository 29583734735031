import React from "react";
import {IDrawer, Text, Stack, Flex} from "@chakra-ui/core";
import ArrowLeftIcon from "@pency/components/icons/ArrowLeft";
import ShareIcon from "@pency/components/icons/Share";
import FormControl from "@pency/components/form/FormControl";
import Button from "@pency/components/controls/Button";
import FormLabel from "@pency/components/form/FormLabel";
import Stepper from "@pency/components/inputs/Stepper";
import Textarea from "@pency/components/inputs/Textarea";
import {DrawerBody, DrawerFooter} from "@pency/components/structure/Drawer";
import CustomDrawer from './CustomDrawer'

import SummaryButton from "../SummaryButton";

import {Product, Variant} from "~/product/types";
import ProductVariantForm from "~/product/forms/ProductVariantForm";
import TruncatedText from "~/ui/feedback/ToggleableText";
import {useTranslation} from "~/i18n/hooks";
import {useToast} from "~/hooks/toast";
import {useAnalytics} from "~/analytics/hooks";
import {useTenant} from "~/tenant/hooks";
import Carousel from "~/ui/feedback/Carousel";

interface Props extends Omit<IDrawer, "children"> {
  onSubmit: (product: Product, options: Variant[], count: number, note: string) => void;
  product: Product;
  isOpen?: boolean;
  OPTvariant?: string;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const CartItemDrawer: React.FC<Props> = ({onClose, product, isOpen, setIsOpen, onSubmit, OPTvariant }) => {
  const [count, setCount] = React.useState(1);
  const [note, setNote] = React.useState("");
  const t = useTranslation();
  const log = useAnalytics();
  const toast = useToast();
  const {flags, tier} = useTenant();
  // Optimize Variants - OPTvariant
  const showShareIcon = OPTvariant === '0' || OPTvariant === '3';
  const showShareTextButton = OPTvariant === '1' || OPTvariant === '2';
  const useAddToCartText = OPTvariant === '2' || OPTvariant === '3';

  function handleSubmit(options: Variant[]) {
    onSubmit(product, options, count, note);
  }

  function handleShare() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      navigator
      .share({
        title: product.title,
        text: product.description,
        url: window.location.href,
      })
      .then(() => {
        toast({
          status: 'success',
          title: t("cartItemDrawer.share.prompt.title"),
          description: t("cartItemDrawer.share.prompt.description"),
        });

        log.share(product, "mobile");
      })
      .catch(() => {
        console.info("El dialogo de share fue cerrado");
      });
     }
   else {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          toast({
            status: "success",
            title: t("cartItemDrawer.share.clipboard.title"),
            description: t("cartItemDrawer.share.clipboard.description"),
            position: 'top-left'
          });

          log.share(product, "desktop");
        })
        .catch(() => {
          toast({
            status: 'error',
            title: t("cartItemDrawer.share.clipboard.error.title"),
            description: t("cartItemDrawer.share.clipboard.error.description"),
          });
        });
    }
  }

  function handleNoteChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNote(event.target.value);
  }

  React.useLayoutEffect(() => {
    if (product) {
      log.viewProduct(product);
    }
  }, [product, log]);

  const closeDrawer = (e) => {
    setIsOpen?.(false)
    setTimeout(() => {
      onClose?.(e)
    },200)
  }

  // If we get here by any point, return null
  if (product.type === "hidden") return null;

  return (
    <CustomDrawer isOpen={isOpen!} isDisabled={true} id="cart-item">
      <ProductVariantForm
        defaultValues={product.options!}
        type={product.type}
        onSubmit={handleSubmit}
      >
        {({form, submit, isLoading, watch}) => {
          const variants = Object.values(watch());
          const items = [
            {
              id: "temp",
              note: "",
              product,
              variants,
              count,
            },
          ];

          return (
            <>
              <DrawerBody paddingX={0}>
                <ArrowLeftIcon
                  background="white"
                  boxShadow="md"
                  cursor="pointer"
                  left={0}
                  marginTop={4}
                  paddingX={4}
                  paddingY={3}
                  position="absolute"
                  roundedRight="lg"
                  top={0}
                  zIndex={1}
                  onClick={(e) => closeDrawer(e)}
                />
                {showShareIcon &&
                  <ShareIcon
                    background="white"
                    boxShadow="md"
                    cursor="pointer"
                    marginTop={4}
                    paddingX={4}
                    paddingY={3}
                    position="absolute"
                    right={0}
                    roundedLeft="lg"
                    top={0}
                    zIndex={1}
                    onClick={handleShare}
                />}
                {Boolean(product.images?.length) && (
                  <Carousel
                    baseHeight="30vh"
                    maxHeight={500}
                    srcSet={tier === "commercial" || "PLAN_COMMERCIAL" || "PLAN_PREMIUM" || "PLAN_TRIAL" ? product.images : product.images.slice(0, 1)}
                  />
                )}
                <Stack
                  shouldWrapChildren
                  direction="column"
                  flex={1}
                  marginTop={product.images?.length ? 0 : 16}
                  paddingTop={4}
                  paddingX={{base: 4, sm: 12}}
                  spacing={6}
                >
                  <Stack spacing={2}>
                    <Text
                      fontSize="2xl"
                      fontWeight="bold"
                      lineHeight="normal"
                      overflowWrap="break-word"
                    >
                      {product.title}
                    </Text>
                    {product.description && (
                      <TruncatedText
                        color="gray.500"
                        fontSize="md"
                        limit={280}
                        whiteSpace="pre-line"
                      >
                        {product.description}
                      </TruncatedText>
                    )}
                  </Stack>
                  {product.options?.length ? form : null}
                  <Flex alignItems="center" justifyContent="space-between">
                    <FormLabel padding={0}>{t("common.count")}</FormLabel>
                    <Stepper min={1} value={count} onChange={setCount} />
                  </Flex>
                  {showShareTextButton &&
                    <Flex alignItems="center" justifyContent="center">
                      <Button 
                        variant="ghost"
                        size="lg"
                        variantColor="primary"
                        width="100%"
                        onClick={handleShare}
                      >
                        {t("common.share")}
                      </Button>
                    </Flex>
                  }
                  {flags?.includes("note") && (
                    <FormControl label={t("cartItemDrawer.comments.label")}>
                      <Textarea
                        max={140}
                        placeholder={t("cartItemDrawer.comments.placeholder")}
                        value={note}
                        onChange={handleNoteChange}
                      />
                    </FormControl>
                  )}
                </Stack>
              </DrawerBody>
              <DrawerFooter>
                {["unavailable", "available", "promotional", "variant"].includes(product.type) && (
                  <SummaryButton
                    isDisabled={product.type === "unavailable"}
                    isLoading={isLoading}
                    items={items}
                    onClick={(event) => {
                      event.stopPropagation();

                      submit();
                    }}
                  >
                    {useAddToCartText ? t("common.addToCart") : t("common.add")}
                  </SummaryButton>
                )}
                {product.type === "ask" && (
                  <Button
                    boxShadow="lg"
                    size="lg"
                    variantColor="primary"
                    width="100%"
                    onClick={(event) => {
                      event.stopPropagation();

                      submit();
                    }}
                  >
                    {t("common.add")}
                  </Button>
                )}
              </DrawerFooter>
            </>
          );
        }}
      </ProductVariantForm>
    </CustomDrawer>
  );
};

export default CartItemDrawer;
