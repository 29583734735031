import React from "react";
import Image from "@pency/components/display/Image";
import {ImageProps, Stack, Box, Text} from "@chakra-ui/core";
import { formatImageUrl } from "~/product/utils/formatImage";

interface Props {
  srcSet: string[];
  baseHeight: ImageProps["minHeight"];
  maxHeight: ImageProps["maxHeight"];
}

const Carousel: React.FC<Props> = ({baseHeight = 256, maxHeight = "100%", srcSet = []}) => {
  const [isToggled, toggle] = React.useState<boolean>(false);

  return (
    <Stack
      isInline
      cursor="pointer"
      flexShrink={0}
      height="100%"
      maxHeight={isToggled ? maxHeight : baseHeight}
      minHeight={baseHeight}
      overflowX="scroll"
      position="relative"
      style={{scrollSnapType: "x mandatory"}}
    >
      {srcSet.map((src, index) => (
        <Box
          key={src}
          display="inline-flex"
          flexShrink={0}
          height="100%"
          position="relative"
          style={{scrollSnapAlign: "start"}}
          width="100%"
        >
          <Image
            backgroundSize={isToggled ? "contain" : "cover"}
            height="100%"
            src={formatImageUrl(src) }
            onClick={() => toggle(!isToggled)}
          />
          {srcSet.length > 1 && (
            <Box
              backgroundColor="rgba(0,0,0,0.5)"
              borderRadius={4}
              bottom={3}
              padding={1}
              position="absolute"
              right={3}
            >
              <Text color="white" fontSize="sm">
                {index + 1} / {srcSet.length}
              </Text>
            </Box>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export default Carousel;
