import React from "react";
import {Icon, Flex, InputGroup, InputLeftElement, Divider} from "@chakra-ui/core";
import Input from "@pency/components/inputs/Input";
import Select from "@pency/components/inputs/Select";

import ProductContext from "./context";
import {Product} from "./types";

import {extractUniqueBy, filterBy} from "~/selectors/filter";
import {sort} from "~/selectors/sort";
import {groupBy} from "~/selectors/group";
import {useTranslation} from "~/i18n/hooks";

export function useProducts() {
  const {
    state: {products},
  } = React.useContext(ProductContext);

  return products;
}

export function useProductCategories() {
  const products = useProducts();

  return sort(extractUniqueBy(products, (product) => product.category!));
}

export function useFilteredProducts(selector?: (product: Product) => boolean) {
  const products = useProducts();
  const [categoryFilter, setCategoryFilter] = React.useState("");
  const [queryFilter, setQueryFilter] = React.useState("");
  const t = useTranslation();
  const filtered = selector ? products.filter(selector) : products;
  const productsBySearch = React.useMemo(() => filterBy(filtered, {title: queryFilter}), [
    queryFilter,
    filtered,
  ]);
  const categories = groupBy(
    productsBySearch,
    (product) => product.category,
  ).map(([category, products]): [Product["category"], number] => [category, products.length]);

  function handleCategoryChange(category: Product["category"]) {
    setCategoryFilter(category === categoryFilter ? "" : category || "");
  }

  function handleQueryChange(query: string) {
    setQueryFilter(query);
  }

  return {
    products: productsBySearch,
    state: {category: categoryFilter, query: queryFilter},
    actions: {setQuery: handleQueryChange, setCategory: handleCategoryChange},
    element: (
      <Flex alignItems="center">
        <Select
          flex={{base: 1, sm: "inherit"}}
          fontWeight="500"
          height="100%"
          maxWidth={{base: "100%", sm: "140px"}}
          paddingLeft={0}
          placeholder={t("common.categories")}
          value=""
          variant="unstyled"
          width="auto"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            handleCategoryChange(e.target.value)
          }
        >
          {categories.map(([category, count]) => (
            <option key={category} value={category}>
              {category} ({count})
            </option>
          ))}
        </Select>
        <Divider height={4} orientation="vertical" />
        <InputGroup alignItems="center" flex={{base: 1, sm: "inherit"}} height={10}>
          <InputLeftElement
            children={<Icon color="gray.300" name="search" />}
            color="gray.300"
            fontSize="1.2em"
            top="inherit"
          />
          <Input
            fontSize="md"
            paddingLeft={10}
            placeholder={t("filters.search")}
            value={queryFilter}
            variant="unstyled"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleQueryChange(event.target.value)
            }
          />
        </InputGroup>
      </Flex>
    ),
  };
}
