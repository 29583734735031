import {Event} from "@pency/api/analytics/types";

import analytics from "./instance";

export default {
  track: (event: Event) => {
    // Extract methods
    const {gtag, fbq} = window as any;

    // Overall analytics
    analytics.track(event);

    // Facebook pixel
    if (fbq) {
      fbq("trackCustom", event.action, event);
    }

    // Custom google analytics
    if (gtag) {
      gtag("event", event.action, event);
    }
  },
  pageview: (path: string) => analytics.pageview(path),
};
