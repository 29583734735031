import React from "react";

import Fluid from "./Fluid";
import Block from "./Block";

import {Product} from "~/product/types";
import {ClientTenant} from "~/tenant/types";

interface Props {
  categories: [string, Product[]][];
  flow: ClientTenant["flow"];
  layout: ClientTenant["layout"];
  selected: string;
  onCategoryClick: (category: string) => void;
  onProductClick: (product: Product) => void;
}

const CategoriesList: React.FC<Props> = ({flow, ...props}) => {
  if (flow === "fluid") return <Fluid {...props} />;
  if (flow === "block") return <Block {...props} />;

  return null;
};

export default CategoriesList;
