import React from "react";
import {Stack, PseudoBox} from "@chakra-ui/core";

import ProductsGrid from "../../ProductsGrid";

import {Product} from "~/product/types";

interface Props {
  categories: [string, Product[]][];
  selected: string;
  onProductClick: (product: Product) => void;
}

const Landscape: React.FC<Props> = ({categories, selected, onProductClick}) => {
  return (
    <Stack spacing={{base: 8, sm: 12}} width="100%">
      {categories.map(([category, products]) => {
        return (
          <PseudoBox key={category} as="section">
            <ProductsGrid
              category={category}
              data-test-id="category"
              flow="fluid"
              isSelected={category === selected}
              layout="landscape"
              products={products}
              onProductClick={onProductClick}
            />
          </PseudoBox>
        );
      })}
    </Stack>
  );
};

export default Landscape;
