import React from "react";
import {ThemeProvider} from "@chakra-ui/core";

import {ClientTenant, Context, State} from "./types";

import getTheme from "~/theme";

interface Props {
  initialValue: ClientTenant;
}

const TenantContext = React.createContext({} as Context);

const TenantProvider: React.FC<Props> = ({children, initialValue: tenant}) => {
  const state: State = {tenant};

  return (
    <ThemeProvider theme={getTheme(tenant.color)}>
      <TenantContext.Provider value={{state}}>{children}</TenantContext.Provider>
    </ThemeProvider>
  );
};

export {TenantProvider as Provider, TenantContext as default};
