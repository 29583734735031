import React from "react";
import FormControl from "@pency/components/form/FormControl";
import Input from "@pency/components/inputs/Input";
import { InputRightElement, Text, InputGroup } from "@chakra-ui/core";

import { LongText, TextField } from "~/tenant/types";
import { useTranslation } from "~/i18n/hooks";

interface Props {
  value: string;
  field: TextField | LongText;
  onChange: (value: string) => void;
}

const TextFieldInput: React.FC<Props> = ({ value, field, onChange }) => {
  const t = useTranslation()
  const getValueLength = () => {
    return value === undefined ? 0 : value.length;
  };

  return (
    <FormControl
      help={field.note}
      isRequired={field.required}
      label={t(field.title)}
      width="100%"
    >
      <InputGroup>
        <Input
          maxLength={70}
          paddingX={0}
          roundedRight={0}
          value={value || ""}
          variant="flushed"
          onChange={(event) => onChange(event.target.value || "")}
        />
        <InputRightElement
          children={<Text top='2.6rem' pos='absolute' color="primary.500">{`${getValueLength()}/${70}`}</Text>}
        />
      </InputGroup>
    </FormControl>
  );
};

export default TextFieldInput;
