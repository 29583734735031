import React from "react";
import {Stack, PseudoBox} from "@chakra-ui/core";

import ProductsGrid from "../../ProductsGrid";

import {Product} from "~/product/types";

interface Props {
  categories: [string, Product[]][];
  selected: string;
  onCategoryClick: (category: string) => void;
  onProductClick: (product: Product) => void;
}

const Portrait: React.FC<Props> = ({categories, selected, onCategoryClick, onProductClick}) => {
  return (
    <Stack spacing={0} width="100%">
      {categories.map(([category, products]) => {
        return (
          <PseudoBox
            key={category}
            _first={{borderTopWidth: 1}}
            as="section"
            borderBottomWidth={1}
            borderColor="gray.200"
          >
            <ProductsGrid
              category={category}
              data-test-id="category"
              flow="block"
              isSelected={category === selected}
              layout="portrait"
              products={products}
              onCategoryClick={onCategoryClick}
              onProductClick={onProductClick}
            />
          </PseudoBox>
        );
      })}
    </Stack>
  );
};

export default Portrait;
