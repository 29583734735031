import {Variant} from "./types";

import {groupBy} from "~/selectors/group";

export function getVariantsString(variants: Variant[]): string {
  if (!variants?.length) return "";

  return variants
    .map((option) => {
      const groups = groupBy(option.value ?? [], ({title}) => title);

      if (!groups?.length) return "";

      return `${option.title}: ${groups
        .map(([title, items]) => `${title}${items.length > 1 ? ` X${items.length}` : ``}`)
        .join(", ")}`;
    })
    .filter(Boolean)
    .join(" - ");
}

export function getVariantsPrice(variants: Variant[]): number {
  if (!variants?.length) return 0;

  return variants?.reduce((total, option) => {
    if (!option.value?.length) return total;

    return total + option.value.reduce((total, option) => total + Number(option.price || 0), 0);
  }, 0);
}

export function getVariantsPriceRange(variants: Variant[] = []): [number, number] {
  // Store temporal values
  let required: number[][] = [];
  let optional: number[] = [];

  variants.forEach((variant) => {
    // Get prices
    const prices = variant.options.map((option) => option.price).filter(Boolean);

    if (variant.required) {
      // Push an array of required prices
      required.push(prices);
    } else {
      // Concat prices to the list of optional prices
      optional.push(...prices);
    }
  });

  if (required.length) {
    return [
      // Get the sum of all min required variants
      required.reduce((acc, prices) => (prices.length ? acc + Math.min(...prices) : acc), 0),
      // Get the sum of all max required variants
      required.reduce((acc, prices) => (prices.length ? acc + Math.max(...prices) : acc), 0),
    ];
  } else if (optional.length) {
    // Get min and max variants
    return [Math.min(...optional), Math.max(...optional)];
  } else {
    // Return 0
    return [0, 0];
  }
}
