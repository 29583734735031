import React from "react";
import {StackProps} from "@chakra-ui/core";

import {Product} from "../../types";

import Block from "./Block";
import Fluid from "./Fluid";

import {ClientTenant} from "~/tenant/types";

interface Props extends StackProps {
  isSelected: boolean;
  layout: ClientTenant["layout"];
  flow: ClientTenant["flow"];
  onProductClick: (product: Product) => void;
  onCategoryClick?: (category: Product["category"]) => void;
  category: Product["category"];
  products: Product[];
}

const ProductsGrid: React.FC<Props> = ({flow, ...props}) => {
  if (flow === "fluid") {
    return <Fluid {...props} />;
  }

  if (flow === "block") {
    return <Block {...props} />;
  }

  return null;
};

export default ProductsGrid;
