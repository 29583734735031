import React from "react";
import {IDrawer} from "@chakra-ui/core";
import Drawer from "@pency/components/structure/Drawer";

import {CartItem, CheckoutResponse, CostValues} from "../../types";
import {addFinancialCost, getCost, getCount, getFinancialCost, hasDelivery} from "../../selectors";

import Overview from "./Overview";
import Fields from "./Fields";

import {ClientTenant, Field} from "~/tenant/types";
import {useAnalytics} from "~/analytics/hooks";
import { useTranslation } from "~/i18n/hooks";
import { useCart } from "~/cart/hooks";
import Checkout from "./Checkout";
import { hasMercadoPagoEnable, isMercadoPagoSelected } from "~/tenant/selectors";
import { useTenant } from "~/tenant/hooks";
import { PaymentMethods } from "@pency/api/orders/types";

interface Props extends Omit<IDrawer, "children"> {
  onClose: VoidFunction;
  items: CartItem[];
  hasCheckout: boolean;
  fields?: ClientTenant["fields"];
  onCheckout: (fields?: Field[], orderPrices?: CostValues, paymentMethod?: string) => Promise<CheckoutResponse>;
  onDecrease: (id: CartItem["id"]) => void;
  onIncrease: (id: CartItem["id"]) => void;
  isOrderScreen?: boolean
}

const CartSummaryDrawer: React.FC<Props> = ({
  items,
  fields,
  hasCheckout,
  onIncrease,
  onDecrease,
  onCheckout,
  onClose,
  isOrderScreen = false
}) => {
  const [step, setStep] = React.useState(isOrderScreen ? "checkout" : "overview");
  const [validationMessage, setValidationMessage] = React.useState("");
  const [order, setOrder] = React.useState<any>(null)
  const [formData, setFormData] = React.useState<any>([])
  const count = getCount(items);
  const { handleOrder } = useCart()
  const t = useTranslation()
  const log = useAnalytics();
  const tenant = useTenant()
  const fileteredFields = fields?.filter((field) => {
    const shouldRemove = field?.type === 'select' && field?.id?.includes('custom_field')
    if (!shouldRemove) return field
  })
  // Validate tenant has one active field. We exlide payment field.
  const hasNextStep = Boolean(fileteredFields?.find((option) => option.active))  //hasActiveFields || hasActivePaymentMethod
  const handleClose = React.useCallback(() => {
    onClose();
    handleReset();
  }, [onClose]);

  function handleReset() {
    setStep("overview");
  }

  function handlePrevious(to: string) {
    setStep(to)
  }

  async function handleCheckout() {
    setStep("checkout")
  }

  async function handleNext() {
    log.viewFields();
    // Reset validation message 
    setValidationMessage("")
    // Create pre-order
    const preSaveOrder = handleOrder() 
    // Set pre-order for checkout screen
    setOrder(preSaveOrder)

    const nextStep = hasNextStep ? "fields" : "checkout"

    return setStep(nextStep);
  }

  function handleCheckoutCart(response: CheckoutResponse) {
    if (response.isValidationOk) {
      // We need to create the window reference before because Safari doesn't let us execute a window.open after an async operation
      // let tab = window.open("", "_blank") || window;
      window.location.href = response.redirectUrl || "";
    } else {
      // //asignamos el valor a mostrar
      let message: string = "";
      response.validationData?.details?.forEach((product) => {
        message = message.concat(product.title ?? "Producto sin título", " (", product.currentStock?.toString() ?? "", ") \n");
      });

      message = message.concat();
      handleReset()
      setValidationMessage(message);
    }
  }

  async function handleCheckoutWithoutFields() {
    return await onCheckout()
      .then((response) => {
        handleCheckoutCart(response);
      })
      .catch((error) => {
        setValidationMessage(error);
      });
  }

  async function handleOrderCreation(fields: Field[], formValues, total, getTotalWithDelivery) {
    // Payment is made via Mercado Pago Payment Link
    let isPayingWithPaymentLink: PaymentMethods.paymentLink | undefined = undefined
    Object.values(formValues).filter((val: string) => {
      if (val?.includes('Link de Pago')) {
        isPayingWithPaymentLink = PaymentMethods.paymentLink
      }
    })
    const hasTermsAndConditions = tenant.flags?.includes("termsAndConditions") 
    // Payment is made via Mercado Pago checkout PRO
    if (tenant.mercadopago && isMercadoPagoSelected(fields) && !isPayingWithPaymentLink && hasTermsAndConditions) {
      return await handleCheckoutWithFields(fields, formValues, total, getTotalWithDelivery, PaymentMethods.checkoutPro )
    }
    const isMercadoPagoEnable = !isPayingWithPaymentLink && hasMercadoPagoEnable(fields)
    const {opt} = addFinancialCost(formValues, total)
    const tc = getFinancialCost(formValues, total)
    const pricesToSet = {
      cost: getTotalWithDelivery(formValues),
      delivery: hasDelivery(formValues, t, fields) ? getCost(fields) : 0, 
      financial: tc,
      opt: opt === '' ? 'message.noCost' : opt
    }
    const preSaveOrder = handleOrder(pricesToSet) 
    const payment = isMercadoPagoEnable ? PaymentMethods.paymentLink : isPayingWithPaymentLink ? isPayingWithPaymentLink : PaymentMethods.other
    // We could save order in local storage for future display
    setOrder(preSaveOrder)
    setFormData({ formValues, fields, total, getTotalWithDelivery, isPayingWithPaymentLink: payment })
    handleCheckout()
  }

  async function handleCheckoutWithFields(fields: Field[], formValues, total, getTotalWithDelivery, paymentMethod?: PaymentMethods) {    
    // Formating required values to handle checkout operations
    const formValues_r = formValues ? formValues : formData?.formValues
    const total_r = total ? total : formData?.total
    const fields_r = fields ? fields : formData.fields
    const getTotalWithDel_r =  getTotalWithDelivery ? getTotalWithDelivery: formData.getTotalWithDelivery
    const payment_r = paymentMethod ? paymentMethod : formData.isPayingWithPaymentLink

    const {opt} = addFinancialCost(formValues_r, total_r)
    const tc = getFinancialCost(formValues_r, total_r)
    const pricesToSet = {
      cost: getTotalWithDel_r(formValues_r),
      delivery: hasDelivery(formValues_r, t, fields_r) ? getCost(fields_r) : 0, 
      financial: tc,
      opt: opt === '' ? 'message.noCost' : opt
    }
    return await onCheckout(fields_r, pricesToSet, payment_r)
      .then((response) => {
        handleCheckoutCart(response);
      })
      .catch((error) => {
        setValidationMessage(error);
      });
  }

  React.useEffect(() => {
    if (!count) handleClose();
  }, [count, handleClose]);

  React.useEffect(() => {
    // We want to log this only once on mount
    log.viewCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [log]);

  return (
    <Drawer
      id="cart"
      placement="right"
      size="md"
      onAnimationEnd={handleReset}
      onClose={handleClose}
    >
      {step === "overview" && (
        <Overview
          checkoutValidationMessage={validationMessage}
          hasCheckout={hasCheckout}
          hasNextStep={hasNextStep}
          items={items}
          onClose={handleClose}
          onDecrease={onDecrease}
          onIncrease={onIncrease}
          onSubmit={handleNext}
        />
        )}
        {step === "fields" && (
        <Fields
          fields={fields!}
          onClose={handleClose}
          items={items}
          onPrevious={() => handlePrevious('overview')}
          onSubmit={handleOrderCreation}
        />
      )}
      {step === "checkout" && (
        <Checkout
          onClose={handleClose}
          onPrevious={() => handlePrevious(hasNextStep ? "fields" : "overview")}
          onSubmit={hasNextStep ? handleCheckoutWithFields : handleCheckoutWithoutFields}
          order={order}
          fields={fields!}
          items={items}
        />
      )}
    </Drawer>
  );
};

export default CartSummaryDrawer;