import {Box, BoxProps} from "@chakra-ui/core";
import React from "react";
import { useTranslation } from "~/i18n/hooks";

import {NoteField} from "~/tenant/types";

interface Props extends BoxProps {
  field: NoteField;
}

const NoteFieldInput: React.FC<Props> = ({field, ...props}) => {
  const t = useTranslation()
  return (
    <Box
      backgroundColor="primary.50"
      borderRadius="md"
      color="primary.500"
      fontSize={{base: "sm", sm: "md"}}
      paddingX={4}
      paddingY={3}
      {...props}
    >
      {t(field.text)}
    </Box>
  );
};

export default NoteFieldInput;
