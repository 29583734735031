import unfetch from "isomorphic-unfetch";

export default function fetch(
  method: "GET" | "PATCH" | "POST" | "DELETE" | "PUT",
  path: string,
  body: Record<string, any> | null = null,
  headers: Record<string, any> = {},
  mode: RequestInit["mode"] = "cors",
) {
  return unfetch(path, {
    method: method,
    mode,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: ["PATCH", "POST", "PUT"].includes(method) && body ? JSON.stringify(body) : null,
  }).then((res) => (res.ok ? res.json() : Promise.reject(res)));
}
