import React from "react";
import {Grid, GridProps, Stack, Text} from "@chakra-ui/core";

import ProductCard from "../../ProductCard";
import {Product} from "../../../types";

interface Props extends GridProps {
  isSelected: boolean;
  products: Product[];
  category: Product["category"];
  onProductClick: (product: Product) => void;
}

const Portrait: React.FC<Props> = ({category, products, onProductClick, isSelected, ...props}) => {
  const element = React.useRef<HTMLElement>();

  React.useEffect(() => {
    if (isSelected) {
      element.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }
  }, [isSelected]);

  return (
    <Stack ref={element} spacing={4}>
      <Stack
        isInline
        fontSize={{base: "lg", sm: "2xl"}}
        fontWeight={500}
        spacing={1}
        textTransform="capitalize"
      >
        <Text as="h2" data-test-id="title">
          {category}
        </Text>
        <Text color="gray.400">({products.length})</Text>
      </Stack>
      <Grid
        autoRows="auto"
        gridGap={{base: 4, sm: 8}}
        paddingY={0}
        templateColumns={{
          base: "repeat(auto-fill, minmax(140px,1fr))",
          sm: "repeat(auto-fill, minmax(200px,1fr))",
        }}
        {...props}
      >
        {products.map((product) => (
          <ProductCard
            key={product.id}
            layout="portrait"
            product={product}
            onClick={() => onProductClick(product)}
          />
        ))}
      </Grid>
    </Stack>
  );
};

export default Portrait;
