import {Field, ClientTenant} from "./types";

import dates from "~/utils/date";

export function hasMercadoPagoEnable(fields) {
  if (!Boolean(fields?.length)) return false;

  const regexp = new RegExp(/mercado(\s{1})?pago/gim);

  return fields.some((field) => field.value?.match(regexp));
}

export function isMercadoPagoSelected(fields?: Field[]): boolean {
  if (!Boolean(fields?.length)) return false;

  const regexp = new RegExp(/mercado(\s{1})?pago/gim);

  const isPayingWithCheckout = fields.some((field) => field.value?.match(regexp));

  if (isPayingWithCheckout) return isPayingWithCheckout;

  const isPayingWithPaymentLink = fields.some((field) => field?.value?.includes("Link de Pago"));

  if (isPayingWithPaymentLink) return isPayingWithPaymentLink;

  return false;
}

export function getProductsLimit(tier: ClientTenant["tier"]): number {
  switch (tier) {
    case "trial":
    case "free": {
      return 10;
    }
    case "preferential": {
      return 150;
    }
    case "commercial": {
      return Infinity;
    }
    case "PLAN_FREE": {
      return 10;
    }
    case "PLAN_INITIAL": {
      return 50;
    }
    case "PLAN_COMMERCIAL": {
      return 100;
    }
    case "PLAN_PREMIUM": {
      return Infinity;
    }
    case "PLAN_TRIAL": {
      return Infinity;
    }
    default: {
      return 100;
    }
  }
}

export function getRevalidationTime(tier: ClientTenant["tier"]): number {
  switch (tier) {
    case "free":
    case "PLAN_FREE": {
      // Time until next day
      return dates.secondsUntilNextDay;
    }

    case "preferential": {
      // Seconds until next hour
      return dates.secondsUntilNextHour;
    }

    case 'PLAN_COMMERCIAL':
    case 'PLAN_PREMIUM':
    case 'PLAN_INITIAL':
    case 'PLAN_TRIAL':
    case "trial":
    case "commercial": {
      // 10 seconds
      return 10;
    }

    default: {
      // 12 hours
      return dates.twelveHoursInSeconds;
    }
  }
}
