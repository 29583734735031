import React from "react";
import { Checkbox as ChakraCheckbox, CheckboxProps } from "@chakra-ui/core";
import styled from "@emotion/styled";
import { useTranslation } from "~/i18n/hooks";

const StyledCheckbox = styled(ChakraCheckbox)`
  input[type="checkbox"]:checked + [aria-hidden="true"] {
    background-color: #22594b;
    border-color: #22594b;
    box-shadow: none;
  }
  input[type="checkbox"]:focus + [aria-hidden="true"] {
    box-shadow: none;
  }
  div:last-of-type {
    font-size: 0.8rem;
  }

`;

interface Props extends CheckboxProps {
  text: string;
  value: string;
  onClick?: any;
  defaultIsChecked?: boolean;
}

const CommonCheckbox: React.FC<Props> = ({
  text,
  onClick,
  value,
  defaultIsChecked,
}) => {
  const t = useTranslation();

  return (
    <StyledCheckbox
      defaultIsChecked={defaultIsChecked}
      onChange={onClick}
      fontWeight="600"
      value={value}
    >
      {t(text)}
    </StyledCheckbox>
  );
};

export default CommonCheckbox;
