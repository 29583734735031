import React from "react";

import Landscape from "./Landscape";
import Portrait from "./Portrait";

import {Product} from "~/product/types";
import {ClientTenant} from "~/tenant/types";

interface Props {
  categories: [string, Product[]][];
  selected: string;
  layout: ClientTenant["layout"];
  onCategoryClick: (category: string) => void;
  onProductClick: (product: Product) => void;
}

const Fluid: React.FC<Props> = ({layout, ...props}) => {
  if (layout === "landscape") return <Landscape {...props} />;
  if (layout === "portrait") return <Portrait {...props} />;

  return null;
};

export default Fluid;
