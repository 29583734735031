import { Box, Flex, Text } from "@chakra-ui/core";
import React from "react";
import { usePrice } from "~/i18n/hooks";
import { getVariantsPrice, getVariantsString } from "~/product/selectors";

interface Props {
  item: any;
}

const OrderItem: React.FC<Props> = ({ item }) => {

  const hasVariants = getVariantsString(item.variants)
  const prices = getVariantsPrice(item.variants)
  
  const p = usePrice();
  return (
    <Flex display="flex" justifyContent="space-between" borderBottom='1px solid #E4E6E8' paddingY='1rem'>
      <Flex>
        <Box>
          <Text padding="0 0.25rem" backgroundColor="#F2F4F5">
            {item.count}
          </Text>
        </Box>
        <Box marginLeft="0.75rem">
          <Text fontSize="1rem" fontStyle="bold">
            {item.product.title}
          </Text>
          <Text fontSize="1rem" fontStyle="bold">
            {getVariantsString(item.variants)}
          </Text>
          <Text fontWeight={400} fontSize="0.75rem" color="#b8b8b8">
            {item.product.category}
          </Text>
        </Box>
      </Flex>
      <Box>
      <Text>{p(item.product.price * item.count)}</Text>
      {hasVariants && 
      <Text fontSize="1rem" fontStyle="bold">
        {p(prices)}
      </Text>
      }
      </Box>
    </Flex>
  );
};

export default OrderItem;
