import fetch from "~/utils/fetch";

import {getMessage} from "~/order/selectors";
import {ProductStock} from "~/product/types";

export default {
  checkout: ({phone, items, orderId, t, mapPrices, fields, state, preference = null}) =>
    `https://wa.me/${phone}?text=${encodeURIComponent(
      getMessage(items, orderId, t, mapPrices, fields, state, preference ?? undefined),
    )}`,
  updateStock: (products: ProductStock[]) => {
    return fetch(
      "PATCH",
      "/api/productStock",
      {products},
      {
        Authorization: window.localStorage.getItem("token"),
      },
    );
  },
  checkStock: (products: ProductStock[]) => {
    return fetch(
      "POST",
      "/api/productStock",
      {products},
      {
        Authorization: window.localStorage.getItem("token"),
      },
    );
  },
};
