import React from "react";
import FormControl from "@pency/components/form/FormControl";
import RadioGroup, { RadioRow } from "@pency/components/inputs/Radio";
import { CheckboxGroup, Stack, Text } from "@chakra-ui/core";

import { SelectField as SelectFieldInterface } from "~/tenant/types";
import { useTranslation } from "~/i18n/hooks";
import CommonCheckbox from "./Switch";

interface Props {
  value: string;
  field: SelectFieldInterface;
  onChange: (value: string) => void;
}

const SelectField: React.FC<Props> = ({ value, field, onChange }) => {
  const t = useTranslation();
  const [multiple, setMultiple] = React.useState<string[]>([]);

  const formatCost = (option) => {
    let cost =
      option.operation === "percentage" ? `${option.cost}%` : `$${option.cost}`;

    return `${option.label} ${cost}`;
  };

  const formatNote = (option) => {
    if (option.note) return option.note;
    if (option.note === "") return 
    if (option.selected === "") return;
    if (option.selected === "opciones") return option.note;
  };

  const handleChange = (event) => {
    onChange(`${event.target.value}`);
  };

  const handleMultipleChange = (event) => {
    if (multiple.includes(`${event.target.value}`)) {
      const newArr = multiple.filter((mul) => mul !== `${event.target.value}`);
      setMultiple(newArr);
      onChange(newArr.join());
    }

    const newArr = [...multiple, `${event.target.value}`];
    setMultiple(newArr);
    onChange(newArr.join());
  };

  const getValue = (option, formatedLabel) => {
    if (option.selected === "recharge" || option.selected === "discount") {
      return `${formatedLabel}_${option.cost}_${option.operation}_${t(
        option.text
      )}_${field.id.split("_")[0]}`;
    }

    return option.text;
  };
  let optionsToIterate = field.options

  if (optionsToIterate[0].hasOwnProperty('isActive')){
    optionsToIterate = optionsToIterate.filter((option) => option.isActive)
  }

  return (
    <FormControl
      isRequired={field.required}
      label={t(field.title)}
      width="100%"
    >
      {field.selected === "multiple_option" ? (
        <CheckboxGroup
          borderBottomWidth={1}
          paddingX={0}
          paddingY={3}
          rounded="md"
          width="100%"
        >
          {field.options.map((option) => {
            return (
              <Stack
                isInline
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                display="flex"
              >
                <CommonCheckbox
                  text={option.text}
                  key={option.id}
                  value={option.text}
                  onClick={handleMultipleChange}
                  fontSize="md"
                  fontWeight="normal"
                />
                {option.note && (
                  <Text
                    width="100%"
                    color="gray.400"
                    fontSize={{ base: "15px", sm: "sm" }}
                    fontWeight={500}
                    textAlign="end"
                  >
                    {option.note}
                  </Text>
                )}
              </Stack>
            );
          })}
        </CheckboxGroup>
      ) : (
        <RadioGroup value={value} onChange={handleChange}>
          {optionsToIterate.map((option) => {
            const formatedLabel = option.label.replace("_", "");
            return (
              <RadioRow
                key={option.id}
                note={formatNote(option)}
                value={getValue(option, formatedLabel)}
                option={option}
                formatCost={formatCost}
              >
                {t(option.text)}
              </RadioRow>
            );
          })}
        </RadioGroup>
      )}
    </FormControl>
  );
};

export default SelectField;
