import { useEffect, useState } from "react";

export const useExperiment = (experimentId: string) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(
              window.google_optimize.get(experimentId)
            );
            clearInterval(intervalId);
          }
        }, 100);
      })();
    });
    return variant;
}