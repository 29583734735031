import React from "react";

import {Product} from "./types";

import {sortBy} from "~/selectors/sort";

export interface Context {
  state: {
    products: Product[];
  };
}
interface Props {
  initialValues: Product[];
}

const ProductContext = React.createContext({} as Context);

const ProductProvider: React.FC<Props> = ({initialValues, children}) => {
  const products = React.useMemo<Product[]>(() => sortBy(initialValues, (item) => item?.title), [
    initialValues,
  ]);

  const state: Context["state"] = {
    products,
  };

  return <ProductContext.Provider value={{state}}>{children}</ProductContext.Provider>;
};

export {ProductProvider as Provider, ProductContext as default};
