import React from "react";
import { Global, css } from "@emotion/core";
import { FocusLock } from "@chakra-ui/focus-lock";
import { Box, BoxProps } from "@chakra-ui/core";

interface CustomDrawer extends BoxProps {
  id: string;
  isOpen: boolean
  isDisabled?: boolean;
  styles?: any
}

const CustomDrawer: React.FC<CustomDrawer> = ({
  children,
  id,
  isDisabled = false,
  isOpen,
  styles
}) => {  
  return (
    <>
      <Global
        styles={css`
          body {
            overflow: hidden;
            padding-right: 1rem;
            @media (max-width: 480px) {
              padding-right: 0;
            }
          }
        `}
      />
      <FocusLock isDisabled={isDisabled}>
        <Box
          top={0}
          zIndex={5}
          pos="fixed"
          height="100%"
          width="100%"
          backgroundColor="rgba(0, 0, 0, 0.4)"
          display="flex"
          justifyContent="flex-end"
          id={id}
          {...styles?.container}
        >
          <Box
            backgroundColor="white"
            position="relative"
            zIndex={10}
            maxWidth="32rem"
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            transition="all 0.2s ease-in-out"
            transform={isOpen ?'translateX(0)' :'translateX(500px)'}
            {...styles?.drawer}
          >
            {children}
          </Box>
        </Box>
      </FocusLock>
    </>
  );
};

export default CustomDrawer;
