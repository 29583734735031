import React from "react";
import {PseudoBoxProps} from "@chakra-ui/core";

import {Product} from "../../types";

import LandscapeProductCard from "./Landscape";
import PortraitProductCard from "./Portrait";

import {ClientTenant} from "~/tenant/types";

interface Props extends Omit<PseudoBoxProps, "onClick"> {
  product: Product;
  onClick?: (product: Product) => void;
  isRaised?: boolean;
  layout: ClientTenant["layout"];
}

const ProductCard: React.FC<Props> = ({layout, ...props}) => {
  if (layout === "landscape") return <LandscapeProductCard {...props} />;
  if (layout === "portrait") return <PortraitProductCard {...props} />;

  return null;
};

export default ProductCard;
